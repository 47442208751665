<template>
    <div class="landing-view">
        <section class="hero">
            <div class="mobile-hero hide-for-medium-up">
                <div class="copy">
                    <h1>Blijf lekker in je vel</h1>
                    <p>Met Valdispert Natural Gummies</p>
                    <a href="#retailers" class="button">Bestel nu</a>
                </div>
            </div>
            <!-- <img class="mobile-hero hide-for-medium-up" src="/static/img/mobile-hero-bg.jpg" /> -->
            <div class="row hide-for-small">
                <div class="col-12 m-col-9">
                    <h1>Blijf lekker in je vel</h1>
                    <p>Met Valdispert Natural Gummies</p>
                    <a href="#retailers" class="button">Bestel nu</a>
                </div>
            </div>

            <v-scroll-indicator class="hide-for-small"></v-scroll-indicator>
        </section>

        <section class="gummy-natural" data-section="gummy-natural">
            <div class="row">
                <div class="col-12 m-col-5">
                    <img class="landing-gummy" src="/static/img/gummy-natural.jpg" />
                </div>

                <div class="col-12 m-col-5">
                    <!-- <h2>Valdispert Natural</h2> -->
                    <h2>Valdispert Natural Gummies</h2>
                    <p>Zin in de dag, vol energie en lekker in je vel. Natural Gummies van Valdispert helpen je lekker te slapen<sup>1</sup>, dragen bij aan extra energie bij vermoeidheid<sup>5</sup>, of helpen je gefocussed te blijven<sup>3</sup>. Dus wil je een potje chillen, of juist een potje knallen, het begint met onze lekkere gummies met een unieke mix van natuurlijke kruiden en vitamines.</p>
                </div>
            </div>
        </section>

        <!-- <section class="goede-dag" data-section="goede-dag">
            <div class="row">
                <img class="title-img" src="/static/img/goede-dag-title-img.jpg" />
                <h2>Lekker in je vel <br class="hide-for-medium-up" />begint bij:</h2>

                <div class="row m-flex-center">
                    <div class="col-12 m-col-6">
                        <img src="/static/img/landing-vaderzoon.jpg" />
                    </div>
                    <div class="col-12 m-col-6 text-col">
                        <h2>Stralend de dag beginnen</h2>
                        <router-link tag="button" to="/product/healthy-sleep">Meer info</router-link>
                    </div>
                </div>

                <div class="row m-flex-center">
                    <div class="hide-for-small m-col-6 text-col">
                        <h2>Helpt je sneller naar <br/>dromenland</h2>
                        <router-link tag="button" to="/product/natural-sleep">Meer info</router-link>
                    </div>
                    <div class="col-12 m-col-6">
                        <img src="/static/img/landing-slapen.jpg" />
                    </div>
                    <div class="hide-for-medium-up col-12 text-col">
                        <h2>Sneller naar <br/>dromenland</h2>
                        <router-link tag="button" to="/product/natural-sleep">Meer info</router-link>
                    </div>
                </div>

                <div class="row m-flex-center">
                    <div class="col-12 m-col-6">
                        <img src="/static/img/landing-relaxen.jpg" />
                    </div>
                    <div class="col-12 m-col-6 text-col">
                        <h2>Even bijkomen</h2>
                        <router-link tag="button" to="/product/relax-recharge">Meer info</router-link>
                    </div>
                </div>
            </div>
        </section> -->

        <section id="products" class="products blue-slant" data-section="products">
            <img class="title-img" src="/static/img/products-title-img.jpg" />
            <h2>Lekkere gummies die ook <br class="hide-for-small" />nog eens goed voor je zijn.</h2>

            <div class="blue-slant-bg"></div>

            <v-slider class="hide-for-medium-up pot-slider product-wrapper" ref="slider" :key="'retailer-slider'" :dots="false" :arrows="true" :startAt="0">
                <div>
                    <div class="slider-content">
                        <div class="product slapen">
                            <div class="row">
                                <div class="col-12">
                                    <div class="potje-wrapper natural-sleep">

                                        <img class="packshot custom" src="/static/img/packshots/natural-sleep2.png" />
                                        <!--
                                        <svg viewBox="0 0 120 266" class="tak1"><use xlink:href="#lavendel-tak"></use></svg>

                                        <img class="citronella" src="/static/img/floaters/citronella-small.png" />

                                        <svg viewBox="0 0 567.52 362.18" class="wolkje"><use xlink:href="#wolkje"></use></svg>
                                        <svg class="maan"><use xlink:href="#maan"></use></svg>
                                        <svg viewBox="0 0 613.73 592.75" class="kussen"><use xlink:href="#pillow"></use></svg>

                                        <img class="echinacea" src="/static/img/floaters/echinacea.png" />
                                        <img class="gummies" src="/static/img/floaters/gummies.png" /> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <!-- <h3>Potje sneller in <br/>slaap vallen</h3> -->
                                    <h3>Helpt je lekker te slapen<sup>1</sup></h3>
                                    <p>Iedereen verdient het om lekker in slaap te vallen. Dan is dit jouw droompotje.</p>
                                    <router-link class="meer-info green" to="/product/natural-sleep" tag="button">Meer info</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="slider-content">
                        <div class="product stress-focus">
                            <div class="row">
                                <div class="col-12">
                                    <div class="potje-wrapper stress-focus">
                                        <img class="packshot" src="/static/img/packshots/stress-focus.png" />

                                        <img class="rhodiola-right" src="/static/img/floaters/rhodiola_big.png" />
                                        <img class="rhodiola-left" src="/static/img/floaters/rhodiola_small.png" />

                                        <svg viewBox="0 0 513.5 513.5" class="yinyang"><use xlink:href="#yinyang"></use></svg>
                                        <svg viewBox="0 0 542.79 570.26" class="lightbulb"><use xlink:href="#lightbulb"></use></svg>

                                        <svg viewBox="0 0 192.86 463.55" class="tak1"><use xlink:href="#stress-takje"></use></svg>
                                        <svg viewBox="0 0 192.86 463.55" class="tak2"><use xlink:href="#stress-takje"></use></svg>

                                        <img class="gummies" src="/static/img/floaters/gummies.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h3>Helpt je scherp de dag door<sup>3</sup></h3>
                                    <p>Je hebt veel op je bord, je wilt altijd alles geven en helder zijn. Soms kun je een steuntje in de rug goed gebruiken.</p>
                                    <router-link class="meer-info" to="/product/stress-focus" tag="button">Meer info</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="slider-content">
                        <div class="product relaxed">
                            <div class="row">
                                <div class="col-12">
                                    <div class="potje-wrapper relax-recharge">
                                        <img class="packshot" src="/static/img/packshots/relax-recharge.png" />

                                        <svg viewBox="0 0 569.85 473.65" class="tak2"><use xlink:href="#tak2"></use></svg>
                                        <svg viewBox="0 0 569.85 473.65" class="tak2-2"><use xlink:href="#tak2"></use></svg>
                                        <svg viewBox="0 0 138.49 368" class="battery"><use xlink:href="#battery"></use></svg>
                                        <svg viewBox="0 0 538.55 630.46" class="budda"><use xlink:href="#budda"></use></svg>

                                        <img class="gummies" src="/static/img/floaters/gummies.png" />
                                        <img class="kamille" src="/static/img/floaters/kamille.png" />
                                        <img class="kamille-zijkant" src="/static/img/floaters/kamille-zijkant.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h3>Even bijkomen<sup>4</sup></h3>
                                    <p>Goed voor jezelf zorgen is belangrijk. Na druk, druk, druk, pak je even dat momentje voor jezelf om te relaxen<sup>4</sup> en weer fitter te voelen<sup>5</sup></p>
                                    <router-link class="meer-info yellow" to="/product/relax-recharge" tag="button">Meer info</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="slider-content">
                        <div class="product doordromen">
                            <div class="row">
                                <div class="col-12">
                                    <div class="potje-wrapper healthy-sleep">
                                        <img class="packshot" src="/static/img/packshots/healthy-sleep.png" />

                                        <img class="healthy-tak" src="/static/img/floaters/healthy-tak.png" />

                                        <svg viewBox="0 0 411 322.5" class="morning"><use xlink:href="#morning"></use></svg>
                                        <svg viewBox="0 0 611.67 635" class="wekker"><use xlink:href="#wekker"></use></svg>
                                        <svg viewBox="0 0 138.49 368" class="battery"><use xlink:href="#battery"></use></svg>

                                        <img class="passiebloem" src="/static/img/floaters/passiebloem.png" />
                                        <img class="passiebloem-small" src="/static/img/floaters/passiebloem-small.png" />
                                        <img class="gummies" src="/static/img/floaters/gummies.png" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h3>Stralend de dag beginnen<sup>2</sup></h3>
                                    <p>Je lichaam laadt zich op tijdens de nacht om je nieuwe dag te beginnen met een glimlach.</p>
                                    <router-link class="meer-info" to="/product/night-time" tag="button">Meer info</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-slider>

            <div class="product-wrapper content hide-for-small">
                <div class="product slapen">
                    <div class="row">
                        <div class="col-5 m-col-4">
                            <div class="potje-wrapper natural-sleep">
                                <img class="packshot custom" src="/static/img/packshots/natural-sleep2.png" />

                                <!-- <svg viewBox="0 0 120 266" class="tak1"><use xlink:href="#lavendel-tak"></use></svg>

                                <img class="citronella" src="/static/img/floaters/citronella-small.png" />

                                <svg viewBox="0 0 567.52 362.18" class="wolkje"><use xlink:href="#wolkje"></use></svg>
                                <svg class="maan"><use xlink:href="#maan"></use></svg>
                                <svg viewBox="0 0 613.73 592.75" class="kussen"><use xlink:href="#pillow"></use></svg>

                                <img class="echinacea" src="/static/img/floaters/echinacea.png" />
                                <img class="gummies" src="/static/img/floaters/gummies.png" /> -->
                            </div>
                        </div>
                        <div class="col-7 col-offset-1 m-col-4">
                            <h3>Helpt je lekker te slapen<sup>1</sup></h3>
                            <p>Iedereen verdient het om lekker in slaap te vallen. Dan is dit jouw droompotje.</p>
                            <router-link class="meer-info green" to="/product/natural-sleep" tag="button">Meer info</router-link>
                        </div>
                    </div>
                </div>

                <div class="product stress-focus">
                    <div class="row">
                        <div class="col-7 col-offset-0 m-col-3 m-col-offset-5">
                            <h3>Helpt je scherp de dag door<sup>3</sup></h3>
                            <p>Je hebt veel op je bord, je wilt altijd alles geven en helder zijn. Soms kun je een steuntje in de rug goed gebruiken.</p>
                            <router-link class="meer-info greenish" to="/product/stress-focus" tag="button">Meer info</router-link>
                        </div>
                        <div class="col-5 m-col-4">
                            <div class="potje-wrapper stress-focus">
                                <img class="packshot" src="/static/img/packshots/stress-focus.png" />

                                <img class="rhodiola-right" src="/static/img/floaters/rhodiola_big.png" />
                                <img class="rhodiola-left" src="/static/img/floaters/rhodiola_small.png" />

                                <svg viewBox="0 0 513.5 513.5" class="yinyang"><use xlink:href="#yinyang"></use></svg>
                                <svg viewBox="0 0 542.79 570.26" class="lightbulb"><use xlink:href="#lightbulb"></use></svg>

                                <svg viewBox="0 0 192.86 463.55" class="tak1"><use xlink:href="#stress-takje"></use></svg>
                                <svg viewBox="0 0 192.86 463.55" class="tak2"><use xlink:href="#stress-takje"></use></svg>

                                <img class="gummies" src="/static/img/floaters/gummies.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="product relaxed">
                    <div class="row">
                        <div class="col-5 m-col-4">
                            <div class="potje-wrapper relax-recharge">
                                <img class="packshot" src="/static/img/packshots/relax-recharge.png" />

                                <svg viewBox="0 0 569.85 473.65" class="tak2"><use xlink:href="#tak2"></use></svg>
                                <svg viewBox="0 0 569.85 473.65" class="tak2-2"><use xlink:href="#tak2"></use></svg>
                                <svg viewBox="0 0 138.49 368" class="battery"><use xlink:href="#battery"></use></svg>
                                <svg viewBox="0 0 538.55 630.46" class="budda"><use xlink:href="#budda"></use></svg>

                                <img class="gummies" src="/static/img/floaters/gummies.png" />
                                <img class="kamille" src="/static/img/floaters/kamille.png" />
                                <img class="kamille-zijkant" src="/static/img/floaters/kamille-zijkant.png" />
                            </div>
                        </div>
                        <div class="col-7 col-offset-1 m-col-3">
                            <h3>Even bijkomen<sup>4</sup></h3>
                            <p>Goed voor jezelf zorgen is belangrijk. Na druk, druk, druk, pak je even dat momentje voor jezelf om te relaxen<sup>4</sup> en weer fitter te voelen<sup>5</sup></p>
                            <router-link class="meer-info yellow" to="/product/relax-recharge" tag="button">Meer info</router-link>
                        </div>
                    </div>
                </div>

                <div class="product doordromen">
                    <div class="row">
                        <div class="col-7 col-offset-0 m-col-3 m-col-offset-5">
                            <h3>Stralend de dag beginnen<sup>2</sup></h3>
                            <p>Je lichaam laadt zich op tijdens de nacht om je nieuwe dag te beginnen met een glimlach.</p>
                            <router-link class="meer-info" to="/product/night-time" tag="button">Meer info</router-link>
                        </div>
                        <div class="col-5 m-col-4">
                            <div class="potje-wrapper healthy-sleep">
                                <img class="packshot" src="/static/img/packshots/healthy-sleep.png" />

                                <img class="healthy-tak" src="/static/img/floaters/healthy-tak.png" />

                                <svg viewBox="0 0 411 322.5" class="morning"><use xlink:href="#morning"></use></svg>
                                <svg viewBox="0 0 611.67 635" class="wekker"><use xlink:href="#wekker"></use></svg>
                                <svg viewBox="0 0 138.49 368" class="battery"><use xlink:href="#battery"></use></svg>

                                <img class="passiebloem" src="/static/img/floaters/passiebloem.png" />
                                <img class="passiebloem-small" src="/static/img/floaters/passiebloem-small.png" />
                                <img class="gummies" src="/static/img/floaters/gummies.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="retailers" id="retailers" data-section="retailers">
            <div class="row">
                <div class="col-12 m-col-8 m-col-offset-2">
                    <img class="title-img" src="/static/img/about-title-img.jpg" />
                    <h2>Bestel direct</h2>
                    <p>Lekker in je vel begint hier:</p>

                    <div class="retailers-list landing">
                        <a class="bol" target="_blank" href="https://www.bol.com/nl/nl/s/?page=1&searchtext=valdispert+&view=list&filter_N=4276237496&rating=all"><div></div></a>
                        <a class="etos" target="_blank" href="https://www.etos.nl/search/?prefn1=line&prefn2=productSubstance&prefv1=Natural&prefv2=Tablet&q=valdispert"><div></div></a>
                        <a class="kruidvat" target="_blank" href="https://www.kruidvat.nl/search?q=valdispert+gummies&text=valdispert+gummies&searchType=manual"><div></div></a>
                        <a class="koopjes-drogisterij" target="_blank" href="https://www.koopjesdrogisterij.nl/?gclid=EAIaIQobChMIrIvw1Kuh8QIV0O5RCh2YoAYFEAAYASAAEgKR7_D_BwE#sqr:(q[valdispert%20gummies])"><div></div></a>
                        <!-- <a target="_blank" href=""><img src="/static/img/retailers/drogisterij.svg" /></a> -->
                        <a class="de-online-drogist" target="_blank" href="https://www.deonlinedrogist.nl/search/?keywords=valdispert+gummies"><div></div></a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss">
    section.scrolltrigger{
        opacity: 0;
    }
</style>

<script>
    import { gsap, ScrollTrigger } from '@/vendor';
    import Slider from '@/app/components/Carousel';
    import ScrollIndicator from '@/app/components/ScrollIndicator';

    export default {
        name: 'Landing',

        components: {
            'v-slider': Slider,
            'v-scroll-indicator': ScrollIndicator
        },

        data()
        {
            return {
                slickOptions: {
                    arrows: true,
                    dots: true,
                    fade: true,
                    useCSS: false
                }
            };
        },

        mounted()
        {
            /* scrolltriggers */
            let timelines = this.makeTimelines();

            for( let elem of this.$el.children )
            {
                if( timelines[ elem.dataset.section ] )
                {
                    ScrollTrigger.create({
                        animation: timelines[ elem.dataset.section ],
                        trigger: elem,

                        start: 'top 70%'
                    });
                }
            }
        },

        methods: {
            makeTimelines()
            {
                let gummyTl = gsap.timeline({ paused: true });
                gummyTl.from( 'section.gummy-natural img', { opacity: 0, y: 20 }, 0 );
                gummyTl.from( 'section.gummy-natural .col-5:last-of-type', { opacity: 0, x: 20 }, 0.3 );

                let goedeDagTl = gsap.timeline({ paused: true });
                goedeDagTl.from( 'section.goede-dag .row > *', { opacity: 0, y: 20, stagger: 0.1 }, 0 );

                let productsTl = gsap.timeline({ paused: true });
                productsTl.from( 'section.products > img, section.products > h2', { opacity: 0, y: 20, stagger: 0.1 }, 0 );
                productsTl.from( 'section.products > .blue-slant-bg', { opacity: 0 }, 0.3 );
                productsTl.from( 'section.products .product-wrapper .product', { opacity: 0, y: -20, stagger: 0.2 }, 0.3 );

                let aboutTl = gsap.timeline({ paused: true });
                aboutTl.from( 'section.about .col-10 > *', { opacity: 0, y: 20, stagger: 0.1 });

                let timelines = {};
                timelines['gummy-natural'] = gummyTl;
                timelines['goede-dag'] = goedeDagTl;
                timelines['products'] = productsTl;
                timelines['about'] = aboutTl;

                return timelines;
            },

            scrollDown()
            {
                gsap.to( 'body, html', { scrollTop: window.innerHeight, duration: 1, ease: 'sine.inOut' });
            }
        }
    };
</script>
