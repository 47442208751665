<template>
    <div>
        <div :class="{ 'slider-wrapper': true, 'prevent-interaction': preventInteraction }">
            <!-- <h2>Speluitleg {{ currentSlide + 1 }}/5</h2> -->
            <div class="arrows" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter" v-if="arrows">
                <img @click="toPrev" class="prev-arrow" src="/static/img/carousel-arrow.png" />
                <img @click="toNext" class="next-arrow" src="/static/img/carousel-arrow.png" />
            </div>
            <div class="slider-track" ref="sliderTrack" :style="{ 'width': totalSlides * 100 + '%' }">
                <div ref="startClone"></div>
                <slot>

                    <!--  <div class="slide" ref="slides" v-for="n in numSlides">
                        <div class="slide-content">
                            <p>slide {{ n }}</p>
                        </div>
                    </div> -->

                </slot>
                <div ref="endClone"></div>
            </div>

            <div class="nav-wrapper" v-if="dots">
                <div :class="{ 'dot': true, 'current': n - 1 === currentSlide }" v-for="n in numSlides" @click="toIndex(n)" ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .slider-wrapper{
        display:block;
        overflow:hidden;

        & > h2{
            margin-top:0;
        }
    }

    .slider-track{
        display:flex;
        touch-action: pan-y !important;

        &>div{
            flex-grow:1;
            width:100%;
        }
    }

    .hover-pause-area{
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        height:100%;
        width:400px;
    }
</style>

<script>
    import Hammer from 'hammerjs';
    import { gsap } from '@/vendor';

    export default {
        props: {
            autoplay: {
                type: Boolean,
                default: false
            },

            autoplayInterval: {
                type: Number,
                default: 5
            },

            pauseOnHover: {
                type: Boolean,
                default: false
            },

            dots: {
                type: Boolean,
                default: true
            },

            arrows: {
                type: Boolean,
                default: false
            },

            startAt: {
                type: Number,
                default: 0
            }
        },

        data()
        {
            return {
                slides: this.$slots.default.filter( slot => slot.tag ),
                currentSlide: 0,
                realIndex: 1,

                autoplayIntervalRef: null,
                isAutoplaying: this.autoplay,
                preventInteraction: false,
                userHasInteracted: false
            };
        },

        computed: {
            numSlides()
            {
                return this.slides.length;
            },

            totalSlides()
            {
                return this.numSlides + 2;
            }
        },

        watch: {
            realIndex: function( pVal )
            {
                this.triggerAnim();
                this.currentSlide = this.realIndex - 1 < 0 ? this.numSlides - 1 : ( this.realIndex - 1 ) % this.numSlides;
            },

            userHasInteracted: function( pVal )
            {
                if( pVal === true )
                {
                    // console.log( this.anim );
                    this.$emit( 'userHasInteracted' );
                }
            }
        },

        mounted()
        {
            this.resetAutoplayInterval();
            window.addEventListener( 'resize', this.onResize );

            this.hammerManager = new Hammer.Manager( this.$refs.sliderTrack );
            var Swipe = new Hammer.Swipe();
            this.hammerManager.add( Swipe );

            this.hammerManager.on( 'swipeleft', ( e ) =>
            {
                this.isAutoplaying = false;
                this.resetAutoplayInterval();
                this.userHasInteracted = true;
                this.toNext( e );
            });
            this.hammerManager.on( 'swiperight', ( e ) =>
            {
                this.isAutoplaying = false;
                this.resetAutoplayInterval();
                this.userHasInteracted = true;
                this.toPrev( e );
            });

            if( this.startAt )
            {
                this.toIndex( this.startAt );
            }

            this.$refs.startClone.outerHTML = this.slides[ this.slides.length - 1 ].elm.outerHTML;
            this.$refs.endClone.outerHTML = this.slides[0].elm.outerHTML;

            gsap.set( '.slider-track', { x: this.newOffset() + 'px' });

            this.preventOneAnim = false;
        },

        beforeDestroy()
        {
            // this.resetAutoplayInterval();
            this.hammerManager.destroy();
        },

        destroyed()
        {
            window.removeEventListener( 'resize', this.onResize );
        },

        methods: {
            resetAutoplayInterval()
            {
                if( this.autoplayIntervalRef )
                {
                    this.autoplayIntervalRef.kill();
                }
                if( this.isAutoplaying )
                {
                    this.autoplayIntervalRef = gsap.to({ foo: 0 }, this.autoplayInterval, { foo: 1, repeat: -1, onRepeat: this.toNext });
                }
            },

            onResize()
            {
                gsap.set( '.slider-track', { x: this.newOffset() + 'px' });
            },

            triggerAnim()
            {
                if( this.anim )
                {
                    this.anim.kill();
                }
                if( this.realIndex === 0 || this.realIndex === this.totalSlides - 1 )
                {
                    this.preventInteraction = true;
                }

                this.anim = gsap.timeline({ onComplete: this.checkIndex });
                this.anim.to( this.$el.querySelector( '.slider-track' ), 1, { x: this.newOffset() + 'px' }, 0 );

                if( this.arrowTween )
                {
                    this.arrowTween.invalidate().kill();
                }

                if( this.arrows )
                {
                    this.arrowTween = gsap.fromTo( this.$el.querySelector( '.arrows > div' ), 0.3, { autoAlpha: 0 }, { autoAlpha: 1, delay: 1.1 });
                }

                if( this.realIndex % 4 !== 0 || this.preventInteraction === true )
                {
                    if( this.preventOneAnim === true )
                    {
                        this.preventOneAnim = false;
                        return;
                    }
                }

                if( this.realIndex % 4 === 0 )
                {
                    this.preventOneAnim = true;
                }
            },

            checkIndex()
            {
                if( this.realIndex === 0 )
                {
                    this.realIndex = this.numSlides;
                }
                else if( this.realIndex === this.totalSlides - 1 )
                {
                    this.realIndex = 1;
                }

                gsap.set( this.$el.querySelector( '.slider-track' ), { x: this.newOffset() + 'px' });
                this.preventInteraction = false;
            },

            newOffset()
            {
                let slideWidth = this.slides[0].elm.getBoundingClientRect().width;
                return this.realIndex * slideWidth * -1;
            },

            toPrev( e )
            {
                if( e )
                {
                    this.isAutoplaying = false;
                    this.userHasInteracted = true;
                    if( this.autoplayIntervalRef )
                    {
                        this.autoplayIntervalRef.kill();
                    }
                }
                if( this.preventInteraction )
                {
                    return;
                }

                this.realIndex = this.realIndex - 1 < 0 ? this.totalSlides - 1 : this.realIndex - 1;
            },

            toNext( e )
            {
                if( e )
                {
                    this.isAutoplaying = false;
                    if( this.autoplayIntervalRef )
                    {
                        this.autoplayIntervalRef.kill();
                    }
                    this.userHasInteracted = true;
                }
                if( this.preventInteraction )
                {
                    return;
                }

                this.realIndex = ( this.realIndex + 1 ) % this.totalSlides;
            },

            toIndex( pIndex )
            {
                if( this.preventInteraction )
                {
                    return;
                }

                this.$emit( 'userHasInteracted' );
                this.realIndex = pIndex;
            },

            onMouseLeave()
            {
                if( this.pauseOnHover && this.isAutoplaying )
                {
                    if( this.autoplayIntervalRef )
                    {
                        this.autoplayIntervalRef.play();
                    }
                }
            },

            onMouseEnter()
            {
                if( this.pauseOnHover )
                {
                    if( this.autoplayIntervalRef )
                    {
                        this.autoplayIntervalRef.pause();
                    }
                }
            }
        }
    };
</script>
