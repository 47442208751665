<template>
    <div class="scroll-indicator">
        <div class="mouse"></div>
        <div class="wheel"></div>
    </div>
</template>

<style lang="scss" scoped>
    .scroll-indicator{
        position:absolute;
        bottom:2rem;
        left:50%;
        tranform:translateX(-50%);
    }
    .mouse{
        width:2rem;
        height:3.5rem;

        display:block;
        border: 2px solid white;
        border-radius:1rem;
    }

    .wheel{
        width:0.5rem;
        height:0.75rem;

        background-color:white;
        position:absolute;
        left:50%;
        top:10%;

        border-radius:100px;
    }

</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        mounted()
        {
            gsap.set( '.wheel', { xPercent: -50 });
            this.tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });

            this.tl.fromTo( '.wheel', { y: 0, autoAlpha: 1 }, { y: 15, autoAlpha: 0, duration: 1 });
            this.tl.to( '.wheel', { y: 0, duration: 0.01 });
            this.tl.to( '.wheel', { autoAlpha: 1, duration: 0.3 });

            this.onScrollRef = this.onScroll;
            window.addEventListener( 'scroll', this.onScrollRef );
        },

        methods: {
            onScroll()
            {
                this.tl.kill();
                gsap.to( '.scroll-indicator', { autoAlpha: 0 });
            }
        }
    };
</script>
