<template>
    <div class="main-layout">
        <div class="header" >
            <div class="logo" @click="toLanding">
                <img src="/static/img/valdispert-logo.svg" />
            </div>
        </div>

        <div :class="{ 'content': true, [ routeClass ]: true }">
            <slot></slot>

            <section class="footer">
                <div class="row">
                    <div class="col-12 m-col-6">
                        <div class="logo" @click="toLanding">
                            <img src="/static/img/valdispert-logo.svg" />
                            <p>De natuurlijke rustgever sinds 1924</p>
                        </div>
                    </div>
                    <div class="col-12 m-col-6">
                        <div class="row">
                            <div class="col-12 m-col-6">
                                <p>Vemedia informatielijn:</p>
                                <p><strong><a href="tel:09002333666">0900 2333666</a></strong></p>

                                <br/>
                                <p>Wil je ons volgen?</p>
                                <div class="socials">
                                    <a class="facebook" href="https://www.facebook.com/valdispertNL/" target="_blank"><img src="/static/img/facebook.png" /></a>
                                    <a class="instagram" href="https://www.instagram.com/valdispert_nl/" target="_blank"><img src="/static/img/instagram.png" /></a>
                                </div>

                            </div>
                            <div class="col-12 m-col-6 hide-for-small">
                                <p>Adres</p>
                                <p>Vemedia B.V.</p>
                                <p>Verrijn Stuartweg 60</p>
                                <p>1112 AX Diemen</p>

                                <br/>
                                <p>Postbus 171</p>
                                <p>1110 BC Diemen</p>

                                <br/>
                                <p><a href="mailto:product@vemedia.nl" target="_blank">product@vemedia.nl</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <div class="row text-center">
                    <div class="col-12">
                        <p><a href="/static/pdf/gebruiksvoorwaarden.pdf" target="_blank">Gebruiksvoorwaarden</a> | &copy; 2021 Vemedia</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        computed: {
            routeClass()
            {
                return 'something';
                // return this.$route.name.toLowerCase() + '-view';
            }
        },

        methods: {
            toLanding()
            {
                this.$router.push( '/' );
            }
        }
    };
</script>
